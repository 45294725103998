<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-edutrain-i07">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <!-- popup-inner > popup-header -->
        <header class="popup-header">
          <h3 class="title">개인금융 – 교과목</h3>
        </header>
        <!-- popup-inner > popup-content -->
        <div class="popup-content">
          <div class="kb-table kb-table-striped">
            <table>
              <colgroup>
                <col style="width:130px;" />
                <col style="width:110px;" />
                <col style="width:80px;" />
                <col style="width:120px;" />
                <col style="width:auto;" />
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">분야</span></th>
                <th><span class="th-title">과정구분</span></th>
                <th><span class="th-title">매체</span></th>
                <th><span class="th-title">주관처</span></th>
                <th><span class="th-title">과정명</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><span class="td-text">자산관리</span></td>
                <td><span class="td-text">필수</span></td>
                <td><span class="td-text">통신</span></td>
                <td><span class="td-text">금융연수원</span></td>
                <td><a href="javascript:" class="td-link"><span class="td-text">KB 자산관리상담실무(2022-02)</span><i class="icon-arrow"></i></a></td>
              </tr>
              <tr>
                <td><span class="td-text">자산관리</span></td>
                <td><span class="td-text">필수</span></td>
                <td><span class="td-text">통신</span></td>
                <td><span class="td-text">금융연수원</span></td>
                <td><a href="javascript:" class="td-link"><span class="td-text">개인고객상담기초(2018-02)</span><i class="icon-arrow"></i></a></td>
              </tr>
              <tr>
                <td><span class="td-text">자산관리</span></td>
                <td><span class="td-text">필수</span></td>
                <td><span class="td-text">통신</span></td>
                <td><span class="td-text">금융연수원</span></td>
                <td><a href="javascript:" class="td-link"><span class="td-text">KB 기업여신실무</span><i class="icon-arrow"></i></a></td>
              </tr>
              <tr>
                <td><span class="td-text">외환(무역외)</span></td>
                <td><span class="td-text">필수</span></td>
                <td><span class="td-text">통신</span></td>
                <td><span class="td-text">금융연수원</span></td>
                <td><a href="javascript:" class="td-link"><span class="td-text">KB 중소기업여신심사</span><i class="icon-arrow"></i></a></td>
              </tr>
              <tr>
                <td><span class="td-text">자산관리</span></td>
                <td><span class="td-text">필수</span></td>
                <td><span class="td-text">통신</span></td>
                <td><span class="td-text">금융연수원</span></td>
                <td><a href="javascript:" class="td-link"><span class="td-text">KB 수출입실무</span><i class="icon-arrow"></i></a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="kb-table-bottom">
            <p class="text">교과목명 클릭 시 교과목 수강신청 화면으로 이동합니다.</p>
          </div>
        </div>
        <!-- popup-inner > popup-buttons -->
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
          <button class="kb-btn kb-btn-primary kb-btn-lg"><span class="text">확인</span></button>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- [end::popup-container] -->
</template>

<script>
export default {
  name: 'HistoryPersonalCourseModal',
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}){
    const closeModal = () => {
      emit('update:modelValue', false);
    }

    return {
      closeModal
    }
  }
};
</script>