<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-edutrain">
    <lxp-main-header :show-title="true" :show-breadcrumb="true" :show-excel-download="true"/>
    <!-- main-content -->
    <div class="main-content min-component">
    <!-- content-section -->
      <HistoryDashboardCertiHistory/>
      <HistoryJobCertificationList/>
    </div>
    <!-- //main-content -->

  </main>
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HistoryDashboardCertiHistory from "@/components/history/HistoryDashboardCertiHistory";
import HistoryJobCertificationList from "@/components/history/HistoryJobCertificationList";

export default {
  name: 'HistoryJobCertification',
  components: {
    HistoryJobCertificationList,
    HistoryDashboardCertiHistory, LxpMainHeader},
  setup() {}
};
</script>