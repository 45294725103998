<template>
  <section class="content-section section-chart">
    <nav class="kb-nav-round-tabs mb-4">
      <ul class="kb-nav-list">
        <li class="kb-nav-item">
          <a href="javascript:" class="kb-nav-link"
             :class="{ 'is-active' : selectedTab === 'personal' }"
             @click="selectedTab = 'personal'">
            <span class="kb-nav-text">개인금융</span>
          </a>
        </li>
        <li class="kb-nav-item">
          <a href="javascript:" class="kb-nav-link"
             :class="{ 'is-active' : selectedTab === 'corporation' }"
             @click="selectedTab = 'corporation'">
            <span class="kb-nav-text">기업금융</span></a>
        </li>
      </ul>
    </nav>
    <div class="learning-status-wrap">
      <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
        <table>
          <colgroup>
            <col style="width:60px"  />
            <col style="width:100px" />
            <col style="width:auto" />
            <col style="width:150px" />
            <col style="width:220px" />
            <col style="width:100px" />
            <col style="width:90px" />
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">분야</span></th>
            <th><span class="th-title">과정구분</span></th>
            <th><span class="th-title">과정명</span></th>
            <th><span class="th-title">주관처</span></th>
            <th><span class="th-title">연수기간</span></th>
            <th><span class="th-title">수료여부</span></th>
            <th><span class="th-title">점수</span></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in items" :key="idx">
              <td><span class="text">자산관리</span></td>
              <td><span class="text">필수</span></td>
              <td><div class="text-truncate"><strong class="text">{{item.crseNm}}</strong></div></td>
              <td><span class="text">{{item.hostNm}}</span></td>
              <td><span class="text">{{item.startDt}}~{{item.endDt}}</span></td>
              <td><strong class="text text-primary">{{item.finishYn === 'y' ? '수료' : '미수료'}}</strong></td>
              <td><strong class="text">{{item.score}}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </div>
  </section>
</template>

<script>
import {useStore} from "vuex";
import {reactive, ref, watch} from "vue";
import {ACT_GET_YEAR_CERTIFICATION_LIST} from "@/store/modules/history/history";
import {getItems, getPaging, initPaging, lengthCheck} from "@/assets/js/util";
import {useRoute, useRouter} from "vue-router";
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: "HistoryJobCertificationList",
  components: {CommonPaginationFront},
  setup(){

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([]);

    const search = reactive({
      dutyCertiTp: '2',
    })
    const selectedTab = ref('personal');

    const getCertificationList = () => {
      store.dispatch(`history/${ACT_GET_YEAR_CERTIFICATION_LIST}`, {
        dutyCertiTp: search.dutyCertiTp,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
          paging.value = getPaging(res);
        }else{
          items.value = [];
          paging.value.totalCount = 0 ;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getCertificationList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    watch(() => selectedTab.value, () => {
      if(selectedTab.value==='personal') search.dutyCertiTp = '2'
      else search.dutyCertiTp = '1';
      getCertificationList();
    })

    watch(() => route.query, () => {
      if (route.name === 'HistoryJobCertificationList') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }
        getCertificationList();
      }
    });


    return{
      items,
      paging,
      selectedTab,

      pagingFunc,
      getCertificationList,
    }
  }
}
</script>