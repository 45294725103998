<template>
  <section class="content-section section-status section-divider">
    <header class="section-header">
      <h4 class="title">직무인증 이력</h4>
    </header>
    <!--  연수이력 > 대시보드 노출ui  -->
    <div v-if="selectedMenu==='dashboard'" class="roundedbox-wrap">
      <div v-for="(item, idx) in items" :key="idx" class="roundedbox">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{item.dutyCertiTpNm}}</h5>
            <div v-if="item.dutyCertiYn==='y'" class="badge badge-primary"><span class="badge-text">인증</span></div>
            <div v-if="item.dutyCertiYn==='n'" class="badge badge-silver"><span class="badge-text">미션중</span></div>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-text">{{item.completeCnt}}/{{item.totalCnt}}</span>
        </div>
      </div>

      <div v-for="(item, idx) in items" :key="idx" class="roundedbox">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{item.dutyCertiTpNm}}</h5>
            <div v-if="item.dutyCertiYn==='y'" class="badge badge-primary"><span class="badge-text">인증</span></div>
            <div v-if="item.dutyCertiYn==='n'" class="badge badge-silver"><span class="badge-text">미션중</span></div>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <div class="roundedbox-subtitle">{{item.dutyCertiTpNm}}</div>
          <span class="roundedbox-text">{{item.completeCnt == item.totalCnt ? '충족' : '미충족'}}</span>
        </div>
      </div>
    </div>

    <!--  연수이력 > 직무인증이력 노출ui  -->
    <div v-else class="roundedbox-wrap">
      <!-- roundedbox -->
      <div v-for="(item, idx) in items" :key="idx"  class="roundedbox">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">{{item.dutyCertiTpNm}}</h5>
            <div v-if="item.dutyCertiYn==='y'" class="badge badge-primary"><span class="badge-text">인증</span></div>
            <div v-if="item.dutyCertiYn==='n'" class="badge badge-silver"><span class="badge-text">미션중</span></div>
          </div>
          <div class="top-row">
            <h5 class="roundedbox-text">{{item.completeCnt}}/{{item.totalCnt}}</h5>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <a href="javascript:" class="kb-btn kb-btn-outline kb-btn-wide rounded-lg" @click="personalCourseModal = true"><span class="text">교과목 보기</span></a>
        </div>
      </div>

      <!-- roundedbox -->
      <div v-for="(item, idx) in items" :key="idx" class="roundedbox">
        <div class="roundedbox-top">
          <div class="top-row">
            <h5 class="roundedbox-title">직무인증제 부점장 <br/>승진 자격요건</h5>
            <div v-if="item.dutyCertiYn==='y'" class="badge badge-primary"><span class="badge-text">인증</span></div>
            <div v-if="item.dutyCertiYn==='n'" class="badge badge-silver"><span class="badge-text">미션중</span></div>
          </div>
        </div>
        <div class="roundedbox-bottom">
          <span class="roundedbox-subtitle">{{item.dutyCertiTpNm}} - 자산관리컨설팅</span>
        </div>
      </div>

    </div>

  </section>

  <HistoryPersonalCourseModal v-model="personalCourseModal" />
</template>

<script>
import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import {ACT_GET_YEAR_AUTH_LIST} from "@/store/modules/history/history";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useRoute} from "vue-router";
import HistoryPersonalCourseModal from "@/components/history/HistoryPersonalCourseModal";

export default {
  name: "HistoryDashboardCertiHistory",
  components:{HistoryPersonalCourseModal},
  setup(){
    const store = useStore();
    const route = useRoute();
    const items = ref([]);
    const selectedMenu = ref();

    const getYearAuthList = () => {
      store.dispatch(`history/${ACT_GET_YEAR_AUTH_LIST}`, {
        lrnerId: 'S017330' // 임시 하드코딩
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getYearAuthList();

    onMounted(() => {
      if(route.path.indexOf('dashboard')>0){
        selectedMenu.value='dashboard'
      }else{
        selectedMenu.value='jobCerti';
      }
    });

    const personalCourseModal = ref(false);

    return{
      items,
      selectedMenu,
      personalCourseModal,
      getYearAuthList,
    }
  }
}
</script>